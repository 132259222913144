<template>
  <v-row justify="space-around" class="d-flex">
    <div class="text-center">
      <v-avatar size="74" class="mt-1 px-1 primary"
        ><v-icon size="40" color="white">{{ icon }}</v-icon></v-avatar
      >
      <span style="font-size:15px;" class="mt-n1">{{unit}}</span>

    </div>
    <div class="text-center">
      <h1 style="font-size: 30px;" class="blue-grey--text mb-n2 mt-n2">{{ counter }}</h1>
      <span style="font-size:15px;" class="mt-n1">{{description}}</span>
    </div>
  </v-row>
</template>


<script>
export default {
  name: "AvatarCard",
  props: ["counter", "icon", "description", "unit"]
};
</script>

<style scoped></style>
