<template>
  <v-container>
    <v-row v-if="ready">
      <spinner style="margin: 400px 400px;"></spinner>
    </v-row>
    <v-row class="mx-2" v-else>
      <v-snackbar
        v-model="saved"
        top
        right
        :timeout="3000"
        class="white--text"
        :color="colorType"
      >
        {{ message }}
      </v-snackbar>
      <v-col cols="12">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submit)">
            <v-row>
              <v-col cols="12" sm="11">
                <v-toolbar
                  color="transparent"
                  flat
                  elevation="0"
                  class="mt-1 title "
                  height="55px"
                >
                  <v-col cols="12" sm="8" md="8" lg="8">
                    <v-card-text
                      class="mb-n2"
                      style="font-size: 16px; margin-left: -50px; font-weight: bold; "
                      ><a
                        style="margin:auto;font-size: 18px"
                        class="blue-grey--text"
                        @click="move"
                      >
                        <v-icon color="6E746D" class="mt-n1"
                          >mdi-chevron-left</v-icon
                        >Order Reference: {{ details.orderReference }}</a
                      >
                    </v-card-text>
                  </v-col>
                </v-toolbar>
              </v-col>
              <v-col cols="12" sm="8">
                <v-card class="box-shadow-light mt-n6">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left primary white--text">
                            Order Details
                          </th>
                          <th class="text-left primary white--text">
                            Information
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-left">Product Name</td>
                          <td>{{ details.productId.name }}</td>
                        </tr>
                        <tr>
                          <td>Mini Threshold</td>
                          <td>{{ details.productId.minimumThreshold }}</td>
                        </tr>

                        <tr>
                          <td>Phone</td>
                          <td>{{ details.branchId.telephoneNumber }}</td>
                        </tr>
                        <tr>
                          <td>Branch</td>
                          <td>{{ details.branchId.name }}</td>
                        </tr>
                        <tr>
                          <td>Cheque Number</td>
                          <td>{{ details.chequeId.number }}</td>
                        </tr>
                        <tr>
                          <td>Cheque Amount</td>
                          <td>{{ details.chequeId.amount | converter }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
                <v-card class="box-shadow-light" style="margin-top: 30px">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left primary white--text">
                            Order Tracking Details
                          </th>
                          <th class="text-left primary white--text">
                            Information
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Trip Number</td>
                          <td>
                            <v-col
                              class="ml-n3 mt-3"
                              cols="12"
                              sm="7"
                              md="7"
                              xs="7"
                              xl="7"
                              lg="7"
                            >
                              <ValidationProvider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  :error-messages="errors[0]"
                                  v-model="tripNumber"
                                  label="Trip Number"
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                          </td>
                        </tr>
                        <tr>
                          <td>Order Number (Source)</td>
                          <td>
                            <v-col
                              class="ml-n3 mt-3"
                              cols="12"
                              sm="7"
                              md="7"
                              xs="7"
                              xl="7"
                              lg="7"
                            >
                              <ValidationProvider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  :error-messages="errors[0]"
                                  outlined
                                  dense
                                  v-model="orderNumber"
                                  label="Order Number"
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                          </td>
                        </tr>
                        <tr>
                          <td>Delivery Number</td>
                          <td>
                            <v-col
                              class="ml-n3 mt-3"
                              cols="12"
                              sm="7"
                              md="7"
                              xs="7"
                              xl="7"
                              lg="7"
                            >
                              <ValidationProvider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  outlined
                                  dense
                                  :error-messages="errors[0]"
                                  v-model="deliveryNumber"
                                  label="Delivery Number"
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                          </td>
                        </tr>
                        <tr>
                          <td>Net Amount</td>

                          <td>
                            <v-col
                              class="ml-n3 mt-3"
                              cols="12"
                              sm="7"
                              md="7"
                              xs="7"
                              xl="7"
                              lg="7"
                            >
                              <ValidationProvider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  label="Net Amount"
                                  outlined
                                  :error-messages="errors[0]"
                                  dense
                                  v-model="netAmount"
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                          </td>
                        </tr>

                        <tr>
                          <td>Invoice Number</td>

                          <td>
                            <v-col
                              class="ml-n3 mt-3"
                              cols="12"
                              sm="7"
                              md="7"
                              xs="7"
                              xl="7"
                              lg="7"
                            >
                              <ValidationProvider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  label="Invoice Number"
                                  :error-messages="errors[0]"
                                  outlined
                                  dense
                                  v-model="invoice"
                                ></v-text-field>
                              </ValidationProvider>
                            </v-col>
                          </td>
                        </tr>

                        <tr>
                          <td>Assign Vehicle</td>

                          <td>
                            <v-col
                              class="ml-n3 mt-3"
                              cols="12"
                              sm="7"
                              md="7"
                              xs="7"
                              xl="7"
                              lg="7"
                            >
                              <ValidationProvider
                                rules="required"
                                v-slot="{ errors }"
                              >
                                <v-select
                                  :items="cars"
                                  label="Car"
                                  outlined
                                  dense
                                  :error-messages="errors[0]"
                                  v-model="car"
                                  item-value="id"
                                  item-text="registrationNumber"
                                />
                              </ValidationProvider>
                            </v-col>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>

                <v-card style="margin-top:30px">
                  <div>
                    <v-col cols="12" sm="6" md="3">
                      <h3 class="blue-grey--text my-1 ml-6">Delivery Note</h3>
                    </v-col>
                  </div>
                  <div class="mt-1">
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <v-textarea
                          class="mx-4"
                          v-model="deliveryNotes"
                          label="Write here.."
                          counter
                          :error-messages="errors[0]"
                          maxlength="120"
                          full-width
                          single-line
                          outlined
                        ></v-textarea>
                      </ValidationProvider>
                    </v-col>
                  </div>
                </v-card>

                <v-card
                  class="box-shadow-light"
                  style="margin-top: 30px"
                  height="200"
                >
                  <div>
                    <v-col cols="12" sm="6" md="3">
                      <h3 class="blue-grey--text my-1 ml-6">Files</h3>
                    </v-col>
                  </div>

                  <div class="mt-1">
                    <v-card flat height="90" style="width: auto" class="mx-8">
                      <v-col cols="12" sm="12" md="12">
                        <v-file-input
                          accept="image/png, image/jpeg, image/jpg, application/pdf"
                          label="Select files"
                          dense
                          multiple
                          outlined
                          chips
                          color="primary"
                          @change="addFiles"
                        />
                      </v-col>
                    </v-card>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" sm="4" class="ml-n3 mt-1" offset="sm-1">
                <v-card
                  hover
                  height="300px"
                  width="313px"
                  class=" mt-n7 mr-10 "
                  style="background-color: white"
                >
                  <AvatarCard
                    class="mx-2 pt-10"
                    :counter="details.quantity"
                    description="Quantity (ltr)"
                    icon="mdi-car-coolant-level"
                  ></AvatarCard>
                  <v-divider
                    style="margin-top: 30px; margin-bottom: 10px"
                  ></v-divider>

                  <v-col
                    class="mr-4"
                    cols="12"
                    sm="12"
                    md="12"
                    xs="12"
                    xl="12"
                    lg="12"
                  >
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <ValidationProvider
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <v-text-field
                            outlined
                            dense
                            :error-messages="errors[0]"
                            v-model="selectedDate"
                            label="Select Loading Date"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            clearable
                          ></v-text-field>
                        </ValidationProvider>
                      </template>
                      <v-date-picker v-model="selectedDate" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-divider
                    style="margin-top: 0px; margin-bottom: 0px"
                  ></v-divider>

                  <div class="mt-4 mb-3">
                    <v-row class="d-flex justify-center">
                      <v-btn
                        text
                        type="submit"
                        color="primary"
                        :loading="isReviewLoading"
                        :disabled="isReviewLoading"
                        class="white--text ft font-weight-medium mb-2 ml-6"
                        style="margin-right: 40px; "
                      >
                        Mark As Reviewed
                      </v-btn>
                    </v-row>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </form>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import PageTitle from "@/components/PageTitle";
import axios from "axios";
import moment from "moment";
import Spinner from "vue-simple-spinner";
import AvatarCard from "@/components/AvatarCard";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "Field is required"
});
export default {
  name: "PendingDetails",
  components: { AvatarCard, Spinner, ValidationProvider, ValidationObserver },
  data() {
    return {
      isReviewLoading: false,
      ready: false,
      details: {},
      products: [],
      message: "",
      saved: false,
      tripNumber: "",
      menu: false,
      orderNumber: "",
      deliveryNumber: "",
      netAmount: "",
      colorType: "",
      progress: false,
      loading: false,
      deliveryNotes: "",
      previewImage: "",
      date: new Date().toISOString().substr(0, 10),
      selectedDate: "",
      invoice: "",
      car: "",
      cars: [],
      //file:"",
      files: [],
      readers: []
    };
  },

  filters: {
    complete(value) {
      return value ? "Done" : "Undone";
    },
    format(value) {
      return moment(value)
        .subtract(0, "days")
        .calendar();
    },

    converter(value) {
      return "GHS " + value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    }
  },
  methods: {
    markAsReviewed() {
      // let id = this.$route.params.id;
      let orderId = this.details.orderId.id;
      return axios({
        method: "put",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/orders/pending/requests/${orderId}/mark/as/reviewed`,
        data: {
          orderLineItem: [
            {
              id: this.details.id,
              quantity: this.details.quantity,
              orderReference: this.details.orderReference,
              isOrderReviewed: true,
              branchId: this.details.branchId.id,
              productId: this.details.productId.id,
              chequeNumber: this.details.chequeNumber,
              invoiceNumber: this.invoice,
              tripNumber: this.tripNumber,
              orderNumber: this.orderNumber,
              netAmount: parseFloat(this.netAmount),
              loadingDate: this.selectedDate,
              deliveryNote: this.deliveryNotes,
              deliveryNumber: this.deliveryNumber,
              chequeAmount: this.details.chequeId.amount,
              carId: this.car,
              images: []
            }
          ]
        }
      });
    },

    getCars() {
      this.progress = true;
      axios({
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/transports/cars`
      })
        .then(response => {
          //console.log(response);
          if (response.status === 200) {
            this.cars = response.data;
          }
        })
        .catch(err => {
          this.loading = false;
          this.progress = false;
          if (err.statusCode === 401) {
            this.$router.replace({ path: "/login" });
          }
        });
    },
    move() {
      this.$router.push({
        name: "master.pending"
      });
    },
    getDetails() {
      let id = this.$route.params.id;
      //console.log(id);
      this.ready = true;
      axios({
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/orders/line/item/${id}`
      })
        .then(response => {
          this.ready = false;
          if (response.status === 200) {
            this.details = response.data;
          }
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.$router.replace({ name: "/login" });
          } else {
            //console.log(err);
          }
        });
    },

    addFiles(payload) {
      this.files = payload;
    },
    sendInvoiceFiles() {
      let formData = new FormData();
      this.files.forEach(invoice => {
        formData.append("files", invoice);
      });
      return axios({
        method: "put",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data"
        },
        url: `${process.env.VUE_APP_BASE}/orders/pending/requests/upload/invoices?ids=${this.details.id}`,
        data: formData
      });
    },
    async submit() {
      try {
        this.isReviewLoading = true;
        const response = await Promise.all([
          this.markAsReviewed(),
          this.sendInvoiceFiles()
        ]);
        if (response) {
          this.isReviewLoading = false;
          this.saved = true;
          this.message = `Order successfully reviewed`;
          this.colorType = "success";
          setTimeout(() => {
            this.saved = false;
            this.$router.push({ name: "master.pending" });
          }, 3000);
        }
      } catch (e) {
        this.isReviewLoading = false;
        this.colorType = "red";
        this.message = e?.response?.data?.message ?? `Unable to review order`;
        this.saved = false;
      }
    }
  },

  created() {
    this.getDetails();
    this.getCars();
  }
};
</script>

<style scoped>
.container1 {
  display: flex;
  flex-direction: column;
  wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
}

.box {
  height: 140px;
  width: auto;
  margin-top: 90px;
  margin-left: 30px;
  margin-right: 30px;
  padding: 2%;
}

.container2 {
  display: flex;
  flex-direction: row;
  wrap: nowrap;
  justify-content: space-around;
  align-items: baseline;
  align-content: stretch;
}

.box2 {
  height: auto;
  margin-top: -30px;
  padding: 5%;
}

.container3 {
  display: flex;
  flex-direction: column;
  wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.box3 {
  height: 80px;
  width: auto;
  margin: 5px 15px;
  padding: 3%;
}

.container4 {
  display: flex;
  flex-direction: row;
  wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
}

.box4 {
  height: auto;
  width: auto;
  padding: 5%;
}

.flex1 {
  display: flex;
  flex-direction: row;
  wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: center;
}
</style>
